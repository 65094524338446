import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { TableColumn } from "../mortageInfo/mortageInfo";
import './graph.css'

interface MortageInfoProps {
  data: TableColumn[];
}

function computeSecondDerivative(xList: number[], yList: number[]) {
    const n = xList.length;
    let secondDerivatives = [];
  
    for (let i = 1; i < n - 1; i++) {
      const x1 = xList[i - 1];
      const x2 = xList[i];
      const x3 = xList[i + 1];
  
      const y1 = yList[i - 1];
      const y2 = yList[i];
      const y3 = yList[i + 1];
  
      // Check if x values are equal (should not happen in a proper dataset)
      if (x2 === x1 || x3 === x2) {
        // Handle division by zero or invalid input
        secondDerivatives.push(0); // or handle error case appropriately
      } else {
        const secondDerivative = (y1 - 2 * y2 + y3) / ((x3 - x2) * (x2 - x1));
        secondDerivatives.push(secondDerivative);
      }
    }
  
    // Handle edge cases (first and last points where second derivative is not computable)
    secondDerivatives = [0, ...secondDerivatives, 0];
  
    return secondDerivatives;
  }

export const MuiGraph: React.FC<MortageInfoProps> = (attrs) => {
  const xList = Array.from({ length: attrs.data.length / 12 }, (_, i) => i + 1);
  const yList = attrs.data.filter(element => element.month % 12 === 0).map((object) => {
    return object.fortune;
  });

  return (
    <LineChart
      xAxis={[{ data: xList }]}
      series={[
        {
            type:"line",
            data: yList,
            valueFormatter: (value) => (value == null ? "NaN" : value.toString()),
            // showMark: false
        },
        // {
        //     type:"line",
        //     data: computeSecondDerivative(newXList, newYList),
        //     valueFormatter: (value) => (value == null ? "NaN" : value.toString()),
        //     showMark: false
        // },
      ]}
      height={200}
      width={400}
      className="paddingSides"
      margin={{ top: 10, bottom: 20 }}
    />
  );
};
