export const roundNumber = (num: number) => {
    return Number(num.toFixed(0))
}
export const visualizeNumber = (value: number) => {
    // Add commas
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const validNumber = (value: string) => {
    // Remove non-digit characters
    return Number(value.replace(/\D/g, ''));
}