import React, { useState } from "react";
import { TableColumn } from "../mortageInfo/mortageInfo";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./table.css";
import { visualizeNumber } from "../../utils/general";

interface TableProps {
  data: TableColumn[];
}
const formatNumberForTable = (params: {value: number}) => {
  return visualizeNumber(params.value)
}

export const DataTable: React.FC<TableProps> = (attrs) => {
  const [colDefs, setColDefs] = useState([
    // { field: "month", flex: 1},
    { headerName: "חודש", field: "month", valueFormatter: formatNumberForTable, flex: 1 },
    { headerName: "חוב משכנתא", field: "remainingPrincipal", valueFormatter: formatNumberForTable, flex: 2 },
    { headerName: "משכנתא חודשי", field: "monthlyPayment", valueFormatter: formatNumberForTable, flex: 2 },
    { headerName: "שכירות", field: "rent", valueFormatter: formatNumberForTable, flex: 2},
    { headerName: "מהכיס עד כה", field: "extraMoneySoFar", valueFormatter: formatNumberForTable, flex: 2},
    { headerName: "ערך הבית", field: "assetValue", valueFormatter: formatNumberForTable, flex: 2 },
    { headerName: "עמלת יציאה", field: "earlyDisposeFee", valueFormatter: formatNumberForTable, flex: 1 },
    { headerName: "רווח", field: "profit", valueFormatter: formatNumberForTable, flex: 2 },
    { headerName: "הון לאחר מכירה", field: "fortune", valueFormatter: formatNumberForTable, flex: 3 }
  ]);


  return (
    <div
      className="ag-theme-quartz" // applying the grid theme
      style={{ height: 400, width: '-webkit-fill-available' }} // the grid will fill the size of the parent container
    >
      <AgGridReact rowData={attrs.data} columnDefs={colDefs as any} enableRtl={true} />
    </div>
  );
};
