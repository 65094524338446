import React, { useState } from "react"
import { fixedRateMonthlyReturn, calculateEqualPrincipalMonthlyPayments, calculateRemainingPrincipal, spizerRemainingBalance } from "../../utils/formulas"
import { DataTable } from "../table/dataTable"
import { MuiGraph } from "../graph/graph"
import './mortageInfo.css'
import { roundNumber } from "../../utils/general"

interface MortageInfoAttrs {
    apartmentPrice: number;
    // TODO: don't send both here, just the sum of them
    initialFortune: number;
    initialCosts: number;
    combinationName: string
    loanAmount: number
    years: number
    annualValueIncrease: number
    rent: number
}

export interface TableColumn {
    month: number
    remainingPrincipal: number
    monthlyPayment: number
    assetValue: number
    earlyDisposeFee: number
    rent: number
    extraMoneySoFar: number
    profit: number
    fortune: number
}

type DisposalMethod = "Spizer" | "Equal"

export const MortageInfo: React.FC<MortageInfoAttrs> = (attrs) => {
    const { combinationName, loanAmount, years, annualValueIncrease, apartmentPrice, rent, initialFortune, initialCosts } = attrs;
    const [disposalMethod, setDisposalMethod] = useState<DisposalMethod>("Spizer")
    const [interest, setInterest] = useState<number>(5.11)

    const getTableColumnData = (): TableColumn[] => {
        const tableData: TableColumn[] = [];
        const equalPrincipalMonthlyReturn = calculateEqualPrincipalMonthlyPayments(interest, years, loanAmount)
        const totalFirstFortune = initialFortune + initialCosts;
        let extraMoneySoFar = 0;

        for (let i = 1; i < (years * 12) + 1; i++) {
            const assetValue = roundNumber(apartmentPrice * Math.pow((1 + (annualValueIncrease / 100)), (i / 12)));
            const remainingPrincipal = disposalMethod === 'Spizer' ? roundNumber(spizerRemainingBalance(interest, years, loanAmount, i)) : roundNumber(calculateRemainingPrincipal(loanAmount, years, i));
            const earlyDisposeFee = 0;
            const monthlyPayment = disposalMethod === 'Spizer' ? roundNumber(fixedRateMonthlyReturn(interest, years, loanAmount)) : roundNumber(equalPrincipalMonthlyReturn[i]);
            extraMoneySoFar += (monthlyPayment - rent);
            const profit = roundNumber(assetValue - remainingPrincipal - earlyDisposeFee - extraMoneySoFar - totalFirstFortune);
            const fortune = totalFirstFortune + profit

            const current: TableColumn = {
                month: i,
                monthlyPayment,
                assetValue,
                remainingPrincipal,
                earlyDisposeFee,
                rent,
                extraMoneySoFar,
                profit,
                fortune
            }

            tableData[i - 1] = current;
        }

        return tableData;
    }

    return (
        <div className="mortageContainer">
            <div className="mortageBox">
                <div className="inputBlock">
                    <h4 >{combinationName}</h4>
                    <h5>ריבית:</h5>
                    <input className="centered-input" type="number" step={0.1} placeholder="ריבית שנתית" defaultValue={interest} onChange={e => setInterest(Number(e.target.value))}></input>
                    <h5>לוח סילוקין:</h5>
                    <button className={`disposal ${disposalMethod === 'Spizer' && 'chosen'}`} onClick={() => setDisposalMethod('Spizer')}>שפיצר</button>
                    <button className={`disposal ${disposalMethod === 'Equal' && 'chosen'}`} onClick={() => setDisposalMethod('Equal')}>קרן שווה</button>
                </div>
            </div>
            <DataTable data={getTableColumnData()} />
            <MuiGraph data={getTableColumnData()} />
        </div>
    )
}