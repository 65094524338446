
import './App.css';
import { MainPage } from './components/mainPage/mainPage';

function App() {
  return (
    <MainPage/>
  )
}

export default App;
