import React, { useEffect, useState } from "react"
import { MortageInfo } from "../mortageInfo/mortageInfo"
import '../mainPage/mainPage.css'
import './mainPage.css'
import { roundNumber } from "../../utils/general"

interface MainPageProps {

}

// TODO: change apartmentPrice to 1,550,000 if that is its actual value.
export const MainPage: React.FC<MainPageProps> = (attrs) => {
    const [initialCosts, setInitialCosts] = useState<number>(30000)
    const [initialFortune, setInitialFortune] = useState<number>(375000)
    const [apartmentPrice, setApartmentPrice] = useState<number>(1500000)
    const [mortgageAmount, setMortgageAmount] = useState<number>(1125000)
    const [years, setYears] = useState<number>(30)
    const [rent, setRent] = useState<number>(4800)
    const [annualValueIncrease, setAnnualValueIncrease] = useState<number>(5)

    useEffect(() => {
        setInitialFortune(roundNumber(apartmentPrice / 4))
        setMortgageAmount(roundNumber(apartmentPrice / 4 * 3))
    }, [apartmentPrice])

    return (
        <div className="mainContainer">
            <div className="navbar">
                <InputBlock name="מחיר הדירה" value={apartmentPrice} onChange={setApartmentPrice} />
                <InputBlock name="גובה משכנתא" value={mortgageAmount} onChange={setMortgageAmount} />
                <InputBlock name="הון עצמי" value={initialFortune} onChange={setInitialFortune} />
                <InputBlock name="עלויות נלוות" value={initialCosts} onChange={setInitialCosts} />
                <InputBlock name="מספר שנים" value={years} onChange={setYears} />
                <InputBlock name="הכנסה משכירות" value={rent} onChange={setRent} />
                <InputBlock name="עליית ערך שנתית" value={annualValueIncrease} onChange={setAnnualValueIncrease} />
            </div>
            <h3 className="middle">{initialCosts + initialFortune} :סה"כ השקעה ראשונית</h3>
            <div className="body">
                <MortageInfo combinationName={"קבועה לא צמודה"} initialFortune={initialFortune} apartmentPrice={apartmentPrice} initialCosts={initialCosts} loanAmount={mortgageAmount} years={years} annualValueIncrease={annualValueIncrease} rent={rent} />
                <MortageInfo combinationName={"מסלול 2"} initialFortune={initialFortune} apartmentPrice={apartmentPrice} initialCosts={initialCosts} loanAmount={mortgageAmount} years={years} annualValueIncrease={annualValueIncrease} rent={rent} />
            </div>
        </div>
    )
}

interface InputBlockAttrs {
    name: string;
    value: number;
    onChange: (value: React.SetStateAction<number>) => void
}

export const InputBlock: React.FC<InputBlockAttrs> = (attrs) => {
    return (
        <div className="inputBlock">
            <h2>{attrs.name}</h2>
            <input className="centered-input" value={attrs.value} type="number" onChange={e => attrs.onChange((e.target.value as unknown as number) | 0)}></input>
        </div>
    )
}