/// spizer
//monthly return
export const fixedRateMonthlyReturn = (annualInterestRate: number, loanTermYears: number, loanAmount: number): number => {
    const monthlyInterestRate = annualInterestRate / 12 / 100;
    const numPayments = loanTermYears * 12;
    const monthlyPayment = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numPayments)) / (Math.pow(1 + monthlyInterestRate, numPayments) - 1);
    
    return monthlyPayment;
}
//remaining balance
export const spizerRemainingBalance = (annualInterestRate: number, loanTermYears: number, loanAmount: number, paymentsMade: number): number => {
    const monthlyInterestRate = annualInterestRate / 12 / 100;
    const numPayments = loanTermYears * 12;
    const remainingBalance = loanAmount * (Math.pow(1 + monthlyInterestRate, numPayments) - Math.pow(1 + monthlyInterestRate, paymentsMade)) / (Math.pow(1 + monthlyInterestRate, numPayments) - 1);
    
    return remainingBalance;
}

/// EqualPrincipal
//monthly return
export const calculateEqualPrincipalMonthlyPayments = (annualInterestRate: number, loanTermYears: number, loanAmount: number): number[] => {
    const monthlyInterestRate = annualInterestRate / 12 / 100;
    const numPayments = loanTermYears * 12;
    const principalPayment = loanAmount / numPayments;
    let remainingPrincipal = loanAmount;
    let payments: number[] = [0];

    for (let i = 1; i < numPayments + 1; i++) {
        const interestPayment = remainingPrincipal * monthlyInterestRate;
        const totalPayment = principalPayment + interestPayment;
        payments.push(totalPayment);
        remainingPrincipal -= principalPayment;
    }

    return payments;
}
//remaining balance
export const calculateRemainingPrincipal = (loanAmount: number, loanTermYears: number, paymentsMade: number): number => {
    const numPayments: number = loanTermYears * 12;
    const monthlyPrincipalPayment: number = loanAmount / numPayments;
    const remainingBalance: number = loanAmount - (monthlyPrincipalPayment * paymentsMade);

    return remainingBalance;
}

const payments = calculateEqualPrincipalMonthlyPayments(5, 20, 750000)
console.log(payments)